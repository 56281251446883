.comingsoon-wrape {
    width: 100%;
    height: 100vh;
    position: relative;
    background: transparent linear-gradient(88deg, #b21cd8, #1495ff) 0 0 no-repeat;
    background: #1b181f;
}

.comingsoon-wrape .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    max-width: 600px;
    flex-wrap: wrap;
    flex-direction: column;
}

.comingsoon-wrape .inner .globe {
    z-index: 2;
    background: #1b181f;
}
.comingsoon-wrape .inner .globe:before {
    content: "";
    width: 280px;
    height: 280px;
    border-radius: 50%;
    display: inline-block;
    background: rgb(178,28,216);
background: linear-gradient(180deg, rgba(178,28,216,1) 0%, rgba(27,24,31,1) 41%);
    opacity: 0.25;
}

.comingsoon-wrape .inner .globe, .comingsoon-wrape .inner .globe-shadow {
    content: "";
    width: 280px;
    height: 280px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
}
/* 
.comingsoon-wrape .inner:before {
    
} */

.comingsoon-wrape .inner .globe-shadow {
    z-index: 1;
    background: #b21cd8;
    background: radial-gradient(circle, rgba(178,28,216,1) 29%, rgba(27,24,31,1) 100%);
    transform: scale(0.9);
    margin-top: -109px;
    filter: blur(30px);
}

.comingsoon-wrape h2 {
    font-family: "Poppins-Regular";
    text-transform: uppercase;
    color: #ffffff;
    font-size: 30px;
    letter-spacing: 30px;
    position: relative;
    z-index: 3;
    padding-left: 30px;
    text-align: center;
}
.comingsoon-wrape .button-wrape {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 3;
    gap: 15px;
    bottom: 10%;
}
.comingsoon-wrape .admin-sidebar-logo {
    position: absolute;
    width: 150px;
    left: 50%;
    margin-left: -75px;
    top: 10%;
}